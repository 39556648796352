.about{
	display: flex;
	@media (max-width: 991px) {
		flex-wrap: wrap;
	}
	&__content{
		background-color: rgba(244, 244,244, 1);
	    width: 50%;
	    padding: 45px 100px;
	    text-align-last: left;
	    @media (max-width: 991px) {
    		width: 100%;
    		order: 2;
    	}
    	@media (max-width: 767px) {
			padding: 30px 15px;
		}
	}
	&__title{
	    color: #123266;
    	font-family: 'Lato';
	    font-size: 30px;
        font-weight: 700;
        padding-bottom: 20px;
	    @media (max-width: 767px) {
    		font-size: 23px;
    	}
	}
	&__text{
	    color: #111;
		font-family: 'Helvetica';
		font-size: 14px;
		line-height: 20px;
		padding-bottom: 30px;
	}
	&__knob{
		display: flex;
		justify-content: center;
		text-decoration: none;
		max-width: 280px;
		margin: 20px auto 0 0;
	    background-color: rgba(146,144,144,1);
	    color: #ffffff;
        font-family: 'Lato';
	    font-size: 16px;
    	font-weight: 700;
    	padding: 18px 5px;
    	transition: all .5s;
    	@media (max-width: 767px) {
			margin: 0 auto 0 0;
		}
    	&:hover{
    		background-color: rgba(18,50,102,1);
    	}
	}
	&__picture{
	    width: 50%;
	    @media (max-width: 991px) {
    		width: 100%;
    		order: 1;
    	}
	}
	&__img{
		width: 100%;
		height: 100%;
	    object-fit: cover;
    	object-position: center;
	}
}