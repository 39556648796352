@font-face {
    font-family: 'Belgrano';
    src: url('../fonts/Belgrano-Regular.eot');
    src: url('../fonts/Belgrano-Regular.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Belgrano-Regular.woff2') format('woff2'),
        url('../fonts/Belgrano-Regular.woff') format('woff'),
        url('../fonts/Belgrano-Regular.ttf') format('truetype'),
        url('../fonts/Belgrano-Regular.svg#Belgrano-Regular') format('svg');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Helvetica';
    src: url('../fonts/Helvetica-Regular.eot');
    src: url('../fonts/Helvetica-Regular.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Helvetica-Regular.woff2') format('woff2'),
        url('../fonts/Helvetica-Regular.woff') format('woff'),
        url('../fonts/Helvetica-Regular.ttf') format('truetype'),
        url('../fonts/Helvetica-Regular.svg#Helvetica-Regular') format('svg');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Lato';
    src: url('../fonts/Lato-Bold.eot');
    src: url('../fonts/Lato-Bold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Lato-Bold.woff2') format('woff2'),
        url('../fonts/Lato-Bold.woff') format('woff'),
        url('../fonts/Lato-Bold.ttf') format('truetype'),
        url('../fonts/Lato-Bold.svg#Lato-Bold') format('svg');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Lato';
    src: url('../fonts/Lato-Regular.eot');
    src: url('../fonts/Lato-Regular.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Lato-Regular.woff2') format('woff2'),
        url('../fonts/Lato-Regular.woff') format('woff'),
        url('../fonts/Lato-Regular.ttf') format('truetype'),
        url('../fonts/Lato-Regular.svg#Lato-Regular') format('svg');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}