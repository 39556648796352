.minutely{
	&__container{
		max-width: 890px;
    	width: 100%;
		margin: 0 auto;
		padding: 0 15px;
	}
	&__cap{
	    background-position: center;
		background-repeat: no-repeat;
		background-size: cover;
		padding: 180px 0;
		text-align: center;
		position: relative;
		z-index: 1;
		font-family: 'Lato';
		color: #ffffff;
		font-weight: 400;
		@media (max-width: 767px) {
			padding: 50px 0;
		}
		&:after{
			content: '';
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;
			background-color: rgba(0, 0, 0, 0.2);
			z-index: -1;
		}
	}
	&__title{
		max-width: 700px;
		margin: 0 auto;
		font-size: 38px;
		padding-bottom: 30px;
		@media (max-width: 767px) {
			font-size: 30px;
		}
	}
	&__date{
		font-size: 16px;
	}
	&__mass{
		padding-top: 60px;
		padding-bottom: 30px;
		color: #111;
	    font-family: 'Helvetica';
		font-size: 14px;
		font-weight: 400;
		line-height: 22px;
		.fb-comments{
			width: 100%;
			margin: 0 -8px;
		}
	}
	&__actions{
		padding-top: 40px;
		display: flex;
		justify-content: space-between;
		&-prev{
			margin-right: auto;
			width: 50%;
			padding-right: 10px;
			text-align: left;
		}
		&-next{
			margin-left: auto;
			width: 50%;
			padding-left: 10px;
			text-align: right;
		}
		&-ref{
			text-decoration: none;
			font-family: 'Helvetica';
			font-size: 14px;
			font-weight: 400;
			color: #031f51;
		}
	}
	&__networks{
		padding-top: 30px;
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		&-item{
			display: block;
			margin: 5px 10px;
			border-radius: 50%;
			min-width: 37px;
			max-width: 37px;
			height: 37px;
			color: #ffffff;
			font-size: 20px;
			display: flex;
			align-items: center;
			justify-content: center;
			transition: all .5s;
			&.facebook{
			    background: #4b68a2;
			}
			&.twitter{
			    background: #00abf3;
			}
			&.linkedin{
			    background: #0079b4;
			}
			&.whatsapp{
			    background: #01e675;
			}
			&.envelope{
			    background: #707070;
			}
		}
	}
	&__comments{
		padding-top: 40px;
	}
}