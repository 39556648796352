.bespoke{
	background-color: rgb(238,238,238);
	background-image: url(../img/bespoke-detail.png);
	background-repeat: repeat;
    background-size: auto;
    background-position: 50% 50%;
	position: relative;
	padding: 73px 0;
	@media (max-width: 767px) {
		padding: 15px 0;
	}
	&:after{
		content: '';
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		background-color: #ffffff;
		opacity: 0.8;
	}
	&__wrapper{
		position: relative;
		z-index: 2;
		max-width: 960px;
		margin: 0 auto;
		background-color: #ffffff;
		padding: 20px 20px 40px 20px;
		text-align: center;
		@media (max-width: 767px) {
    		padding: 20px 15px 30px 15px;
    	}
	}
	&__title{
	    font-family: 'Lato';
	    font-weight: 400;
	    color: #123266;
	    font-size: 50px;
	    &:after{
	    	content: '';
	    	display: block;
	    	margin: 26px auto;
	    	width: 40px;
	    	height: 2px;
	    	background-color: rgb(51, 51, 51);
	    	@media (max-width: 767px) {
	    		margin: 20px auto;
	    	}
	    }
	    @media (max-width: 767px) {
    		font-size: 30px;
    	}
	}
	&__text{
	    font-family: 'Helvetica';
	    font-weight: 400;
	    font-size: 14px;
        color: #111;
        max-width: 637px;
        margin: 0 auto;
        line-height: 22px;
	}
	&__knob{
		display: block;
		text-decoration: none;
		max-width: 280px;
		margin: 25px auto 0 auto;
	    background-color: rgba(146,144,144,1);
	    color: #ffffff;
        font-family: 'Lato';
	    font-size: 16px;
    	font-weight: 700;
    	padding: 12.5px 5px;
    	transition: all .5s;
    	&:hover{
    		background-color: rgba(18,50,102,1);
    	}
	}
}