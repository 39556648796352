.services{
	padding-top: 70px;
	padding-bottom: 50px;
	@media (max-width: 767px) {
		padding-top: 50px;
		padding-bottom: 30px;
	}
	&__container{
		max-width: 1480px;
		width: 100%;
		margin: 0 auto;
		padding: 0 15px;
	}
	&__set{
		display: flex;
		justify-content: center;
		flex-wrap: wrap;
		margin-left: -10px;
	}
	&__box{
		padding-left: 10px;
		padding-bottom: 20px;
		width: 33.3%;
		@media (max-width: 991px) {
			width: 50%;
		}
		@media (max-width: 767px) {
			width: 100%;
		}
	}
	&__picture{
		display: block;
		position: relative;
		padding-bottom: 68%;
		overflow: hidden;
	}
	&__img{
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		object-fit: cover;
		transition: all .5s;
		&:hover{
			transform: scale(1.06);
		}
	}
	&__name{
		padding-top: 15px;
		text-align: center;
		font-family: 'Helvetica';
		font-weight: 400;
        font-size: 28px;
	    color: #333;
	    @media (max-width: 767px) {
	    	padding-top: 10px;
			font-size: 16px;
		}
	}
}