.cap{
	padding: 185px 0;
	text-align: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 0 50%;
    @media (max-width: 767px) {
		padding: 50px 0;
	}
	&__container{
		max-width: 940px;
    	width: 100%;
		margin: 0 auto;
		padding: 0 15px;
	}
	&__title{
		font-family: 'Lato';
		color: #f9f9f9;
		font-size: 60px;
	    font-weight: 700;
	    line-height: 89px;
	    @media (max-width: 767px) {
    		font-size: 30px;
    		line-height: 45px;
    	}
	}
}