.call{
	padding: 25px 0;
	text-align: center;
	&__container{
		max-width: 1420px;
		width: 100%;
		margin: 0 auto;
		padding: 0 15px;
	}
	&__background{
		padding-top: 138px;
		padding-bottom: 148px;
		background-image: url(../img/call-background.jpg);
		background-repeat: no-repeat;
		background-position: center;
		background-size: cover;
		position: relative;
		z-index: 2;
		&:after{
			content: '';
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;
			background-color: rgba(18, 50, 102, 0.5);
			z-index: -1;
		}
		@media (max-width: 767px) {
			padding: 50px 0;
		}
	}
	&__text{
		font-family: 'Helvetica';
		color: #ffffff;
	    font-size: 20px;
	    line-height: 30px;
	    @media (max-width: 767px) {
			font-size: 16px;
			line-height: 25px;
		}
	}
	&__tel{
		display: inline-block;
		font-family: 'Helvetica';
		font-size: 24px;
	    font-weight: 700;
		color: #ffffff;
		text-decoration: none;
	}
}