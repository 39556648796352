.set{
	background-color: rgb(238,238,238);
	padding: 73px 0;
	@media (max-width: 991px) {
		padding: 50px 0;
	}
	&__container{
		max-width: 990px;
		width: 100%;
		margin: 0 auto;
	}
	&__box{
		display: flex;
		@media (max-width: 991px) {
    		flex-wrap: wrap;
    		margin-bottom: 20px;
    		&:last-child{
    			margin-bottom: 0;
    		}
    	}
	}
	&__content{
		background-color: #ffffff;
	    width: 50%;
	    padding: 30px 18px;
	    @media (max-width: 991px) {
    		width: 100%;
    		order: 2;
    	}
	}
	&__title{
	    color: #123266;
    	font-family: 'Lato';
	    font-size: 50px;
        font-weight: 400;
        text-align: center;
        &:after{
	    	content: '';
	    	display: block;
	    	margin: 26px auto;
	    	width: 40px;
	    	height: 2px;
	    	background-color: rgb(51, 51, 51);
	    	@media (max-width: 767px) {
	    		margin: 20px auto;
	    	}
	    }
	    @media (max-width: 767px) {
    		font-size: 23px;
    	}
	}
	&__text{
	    color: #111;
		font-family: 'Helvetica';
		font-size: 14px;
		text-align: center;
		line-height: 20px;
		ul{
			padding: 20px 40px;
			list-style-type: none;
			li{
				display: flex;
				align-items: center;
				text-align: left;
				padding: 2px 0;
				&:before{
					content: '‎●';
					color: #111;
					font-size: 12px;
					margin-right: 8px;
				}
				a{
					text-decoration: none;
					color: #111;
					font-family: 'Helvetica';
					font-weight: 400;
					font-size: 18px;
				}
			}
		}
	}
	&__knob{
		text-align: center;
		display: block;
		text-decoration: none;
		max-width: 280px;
		margin: 25px auto 0 auto;
	    background-color: rgba(146,144,144,1);
	    color: #ffffff;
        font-family: 'Lato';
	    font-size: 16px;
    	font-weight: 700;
    	padding: 12.5px 5px;
    	transition: all .5s;
    	&:hover{
    		background-color: rgba(18,50,102,1);
    	}
	}
	&__picture{
	    width: 50%;
	    @media (max-width: 991px) {
    		width: 100%;
    		order: 1;
    	}
	}
	&__img{
		width: 100%;
		height: 100%;
	    object-fit: cover;
    	object-position: right;
	}
}

.unit{
	background-color: rgb(238,238,238);
	background-image: url(../img/bespoke-detail.png);
	background-repeat: repeat;
    background-size: auto;
    background-position: 50% 50%;
	position: relative;
	padding: 73px 0;
	@media (max-width: 991px) {
		padding: 15px 0;
	}
	&:after{
		content: '';
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		background-color: #ffffff;
		opacity: 0.8;
	}
	&__wrapper{
		position: relative;
		z-index: 2;
		max-width: 990px;
		margin: 0 auto;
		display: flex;
		@media (max-width: 991px) {
    		flex-wrap: wrap;
    	}
	}
	&__content{
		background-color: #ffffff;
	    width: 50%;
	    padding: 30px 18px;
	    @media (max-width: 991px) {
    		width: 100%;
    		order: 2;
    	}
	}
	&__title{
	    color: #123266;
    	font-family: 'Lato';
	    font-size: 50px;
        font-weight: 400;
        text-align: center;
        &:after{
	    	content: '';
	    	display: block;
	    	margin: 26px auto;
	    	width: 40px;
	    	height: 2px;
	    	background-color: rgb(51, 51, 51);
	    	@media (max-width: 767px) {
	    		margin: 20px auto;
	    	}
	    }
	    @media (max-width: 767px) {
    		font-size: 23px;
    	}
	}
	&__text{
	    color: #111;
		font-family: 'Helvetica';
		font-size: 14px;
		text-align: center;
		line-height: 20px;
		ul{
			padding: 20px 40px;
			list-style-type: none;
			li{
				display: flex;
				align-items: center;
				text-align: left;
				padding: 2px 0;
				&:before{
					content: '‎●';
					color: #111;
					font-size: 12px;
					margin-right: 8px;
				}
				a{
					text-decoration: none;
					color: #111;
					font-family: 'Helvetica';
					font-weight: 400;
					font-size: 18px;
				}
			}
		}
	}
	&__picture{
	    width: 50%;
	    background-color: rgba(224, 224, 224, 1);
	    @media (max-width: 991px) {
    		width: 100%;
    		order: 1;
    	}
	}
	&__img{
		width: 100%;
		height: 100%;
	    object-fit: contain;
    	object-position: center;
	}
}

.cell{
    background-color: #EEEEEE;
	padding-bottom: 73px;
	@media (max-width: 991px) {
		padding: 15px 0;
	}
	&__wrapper{
		max-width: 990px;
		margin: 0 auto;
		display: flex;
		@media (max-width: 991px) {
    		flex-wrap: wrap;
    	}
	}
	&__content{
		background-color: #ffffff;
	    width: 50%;
	    padding: 30px 18px;
	    @media (max-width: 991px) {
    		width: 100%;
    		order: 2;
    	}
	}
	&__title{
	    color: #123266;
    	font-family: 'Lato';
	    font-size: 50px;
        font-weight: 400;
        text-align: center;
        &:after{
	    	content: '';
	    	display: block;
	    	margin: 26px auto;
	    	width: 40px;
	    	height: 2px;
	    	background-color: rgb(51, 51, 51);
	    	@media (max-width: 767px) {
	    		margin: 20px auto;
	    	}
	    }
	    @media (max-width: 767px) {
    		font-size: 23px;
    	}
	}
	&__text{
	    color: #111;
		font-family: 'Helvetica';
		font-size: 14px;
		text-align: center;
		line-height: 20px;
		ul{
			padding: 20px 40px;
			list-style-type: none;
			li{
				display: flex;
				align-items: center;
				text-align: left;
				padding: 2px 0;
				&:before{
					content: '‎●';
					color: #111;
					font-size: 12px;
					margin-right: 8px;
				}
				a{
					text-decoration: none;
					color: #111;
					font-family: 'Helvetica';
					font-weight: 400;
					font-size: 18px;
				}
			}
		}
	}
	&__picture{
	    width: 50%;
	    background-color: rgba(224, 224, 224, 1);
	    @media (max-width: 991px) {
    		width: 100%;
    		order: 1;
    	}
	}
	&__img{
		width: 100%;
		height: 100%;
	    object-fit: contain;
    	object-position: center;
	}
}