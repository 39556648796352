.loader{
    position: fixed;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    background: #182849;
    z-index: 100;
}
.loader .loader__main{
    position: absolute;
    top: 50%;
    left: 50%;
    width: 172px;
    height: 128px;
    margin: 0;
    -webkit-transform: translate(-50%,-50%);
    transform: translate(-50%,-50%);
}
@media (max-width: 550px){
    .loader__main{
        width: 152px;
        height: 108px;
    }
}
.loader__square{
    position: relative;
}
.loader__square:nth-child(1){
    margin-left: 0px;
}
.loader__square:nth-child(2){
    margin-left: 44px;
}
.loader__square:nth-child(3){
    margin-left: 88px;
}
.loader__square:nth-child(4){
    margin-left: 132px;
}
.loader__square span{
    position: absolute;
    top: 0px;
    left: 20px;
    height: 36px;
    width: 36px;
    border-radius: 2px;
    background-color: #ffffff;
}
.loader__square span:nth-child(1){
    top: 0px;
}
.loader__square span:nth-child(2){
    top: 44px;
}
.loader__square span:nth-child(3){
    top: 88px;
}
.loader__square:nth-child(1) span{
    -webkit-animation: animsquare1 2s infinite ease-in;
    animation: animsquare1 2s infinite ease-in;
}
.loader__square:nth-child(2) span{
    -webkit-animation: animsquare2 2s infinite ease-in;
    animation: animsquare2 2s infinite ease-in;
}
.loader__square:nth-child(3) span{
    -webkit-animation: animsquare3 2s infinite ease-in;
    animation: animsquare3 2s infinite ease-in;
}
.loader__square:nth-child(4) span{
    -webkit-animation: animsquare4 2s infinite ease-in;
    animation: animsquare4 2s infinite ease-in;
}

.loader__square span:nth-child(1){
    -webkit-animation-delay: 0.00s;
    animation-delay: 0.00s;
}
.loader__square span:nth-child(2){
    -webkit-animation-delay: 0.15s;
    animation-delay: 0.15s;
}
.loader__square span:nth-child(3){
    -webkit-animation-delay: 0.30s;
    animation-delay: 0.30s;
}
@-webkit-keyframes animsquare1{
    0%,5%,95%,100%{
    -webkit-transform: translate(0px,0px) rotate(0deg);
    transform: translate(0px,0px) rotate(0deg)}30%,70%{
    -webkit-transform: translate(-40px,0px) rotate(-90deg);
    transform: translate(-40px,0px) rotate(-90deg);
    }
}
@keyframes animsquare1{
    0%,5%,95%,100%{
        -webkit-transform: translate(0px,0px) rotate(0deg);
        transform: translate(0px,0px) rotate(0deg)}30%,70%{
        -webkit-transform: translate(-40px,0px) rotate(-90deg);
        transform: translate(-40px,0px) rotate(-90deg);
    }
}
@-webkit-keyframes animsquare2{
    0%,10%,90%,100%{
        -webkit-transform: translate(0px,0px) rotate(0deg);
        transform: translate(0px,0px) rotate(0deg)}35%,65%{
        -webkit-transform: translate(-40px,0px) rotate(-90deg);
        transform: translate(-40px,0px) rotate(-90deg);
    }
}
@keyframes animsquare2{
    0%,10%,90%,100%{
        -webkit-transform: translate(0px,0px) rotate(0deg);
        transform: translate(0px,0px) rotate(0deg)}35%,65%{
        -webkit-transform: translate(-40px,0px) rotate(-90deg);
        transform: translate(-40px,0px) rotate(-90deg);
    }
}
@-webkit-keyframes animsquare3{
    0%,15%,85%,100%{
        -webkit-transform: translate(0px,0px) rotate(0deg);
        transform: translate(0px,0px) rotate(0deg)}40%,60%{
        -webkit-transform: translate(-40px,0px) rotate(-90deg);
        transform: translate(-40px,0px) rotate(-90deg);
    }
}
@keyframes animsquare3{
    0%,15%,85%,100%{
        -webkit-transform: translate(0px,0px) rotate(0deg);
        transform: translate(0px,0px) rotate(0deg)}40%,60%{
        -webkit-transform: translate(-40px,0px) rotate(-90deg);
        transform: translate(-40px,0px) rotate(-90deg);
    }
}
@-webkit-keyframes animsquare4{
    0%,20%,80%,100%{
        -webkit-transform: translate(0px,0px) rotate(0deg);
        transform: translate(0px,0px) rotate(0deg)}45%,55%{
        -webkit-transform: translate(-40px,0px) rotate(-90deg);
        transform: translate(-40px,0px) rotate(-90deg);
    }
}
@keyframes animsquare4{
    0%,20%,80%,100%{
        -webkit-transform: translate(0px,0px) rotate(0deg);
        transform: translate(0px,0px) rotate(0deg)}45%,55%{
        -webkit-transform: translate(-40px,0px) rotate(-90deg);
        transform: translate(-40px,0px) rotate(-90deg);
    }
}