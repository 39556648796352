.quality{
	padding: 15px 0;
	&__container{
		max-width: 900px;
		width: 100%;
		margin: 0 auto;
		padding: 0 15px;
	}
	&__img{
		display: block;
		max-width: 185px;
		width: 100%;
		margin: 0 auto;
	}
}