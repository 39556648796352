.hat{
	background-color: rgb(238,238,238);
	background-image: url(../img/bespoke-detail.png);
	background-repeat: repeat;
    background-size: auto;
    background-position: 50% 50%;
    position: relative;
    padding: 25px 0;
    &:after{
		content: '';
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		background-color: #ffffff;
		opacity: 0.8;
	}
	&__container{
		max-width: 960px;
    	width: 100%;
		margin: 0 auto;
		padding: 0 15px;
		position: relative;
    	z-index: 2;
    	text-align: center;
	}
    &__title{
		font-weight: 400;
	    color: #123266;
	    font-family: 'Lato';
        font-size: 60px;
        line-height: 85px;
        @media (max-width: 767px) {
    		font-size: 25px;
    		line-height: 30px;
    	}
    }
    &__text{
    	padding-top: 15px;
	    font-family: 'Helvetica';
	    font-size: 14px;
    	color: #111;
    	font-weight: 400;
    	line-height: 25px;
    }
}