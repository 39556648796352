.say{
	padding: 15px 0;
	background-color: rgba(22, 37, 67, 1);
	text-align: center;
	&__container{
		max-width: 974px;
		width: 100%;
		margin: 0 auto;
		padding: 0 15px;
	}
	&__wrapper{
		overflow: hidden;
		position: relative;
		&:hover{
			.say__prev{
				left: 0;
			}
			.say__next{
				right: 0;
			}
		}
	}
	&__slider{
		cursor: grab;
		&:active{
			cursor: grabbing
		}
	}
	&__box{
		padding: 60px 148px;
		background-repeat: no-repeat;
		background-position: center;
		background-size: cover;
		&:focus{
			outline: none;
		}
		@media (max-width: 767px) {
			padding: 30px 50px;
		}
	}
	&__arrow{
		cursor: pointer;
		position: absolute;
		top: 50%;
		width: 40px;
		height: 40px;
		z-index: 2;
		transition: all .5s;
		font-size: 35px;
		display: flex;
		justify-content: center;
		align-items: center;
		color: rgba(255, 255, 255, 0.7);
	}
	&__prev{		
		transform: translate(0, -50%) rotate(90deg);
		left: -100px;
		@media (max-width: 767px) {
			left: 0;
		}
	}
	&__next{
		transform: translate(0, -50%) rotate(-90deg);
		right: -100px;
		@media (max-width: 767px) {
			right: 0;
		}
	}
	&__title{
		color: #ffffff;
		font-family: 'Lato';
		font-weight: 700;
		font-size: 35px;
		padding-bottom: 40px;
		@media (max-width: 767px) {
			font-size: 23px;
			padding-bottom: 25px;
		}
	}
	&__text{
		color: #ffffff;
	    font-family: 'Helvetica';
		font-size: 19px;
    	font-weight: 400;
    	@media (max-width: 767px) {
			font-size: 16px;
		}
	}
}