.partners{
	background-color: rgba(49,49,49,1);
	padding-top: 50px;
	padding-bottom: 0;
	&__container{
		max-width: 985px;
		width: 100%;
		padding: 0 15px;
		margin: 0 auto;
	}
	&__set{
		display: flex;
		align-items: center;
		flex-wrap: wrap;
		justify-content: center;
		margin-left: -80px;
	}
	&__item{
		max-width: 214px;
		width: 100%;
		padding-left: 80px;
		padding-bottom: 50px;
	}
	&__logo{
		width: 100%;
	}

}