.advantages{
	background-color: rgb(238,238,238);
	background-image: url(../img/bespoke-detail.png);
	background-repeat: repeat;
    background-size: auto;
    background-position: 50% 50%;
	position: relative;
	padding: 73px 0;
	@media (max-width: 767px) {
		padding: 15px 0;
	}
	&:after{
		content: '';
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		background-color: #ffffff;
		opacity: 0.8;
	}
	&__container{
		z-index: 2;
		position: relative;
		max-width: 990px;
		width: 100%;
		margin: 0 auto;
		padding: 0 15px;
	}
	&__box{
		margin-bottom: 73px;
		display: flex;
		@media (max-width: 991px) {
    		flex-wrap: wrap;
    	}
		&:last-child{
			margin-bottom: 0;
		}
	}
	&__content{
		background-color: #ffffff;
	    width: 50%;
	    padding: 30px 18px;
	    @media (max-width: 991px) {
    		width: 100%;
    		order: 2;
    	}
	}
	&__title{
	    color: #123266;
    	font-family: 'Lato';
	    font-size: 50px;
        font-weight: 400;
        &:after{
	    	content: '';
	    	display: block;
	    	margin: 26px auto;
	    	width: 40px;
	    	height: 2px;
	    	background-color: rgb(51, 51, 51);
	    	@media (max-width: 767px) {
	    		margin: 20px auto;
	    	}
	    }
	    @media (max-width: 767px) {
    		font-size: 23px;
    	}
	}
	&__text{
	    color: #111;
		font-family: 'Helvetica';
		font-size: 14px;
		text-align: center;
		line-height: 20px;
	}
	&__knob{
		text-align: center;
		display: block;
		text-decoration: none;
		max-width: 280px;
		margin: 25px auto 0 auto;
	    background-color: rgba(146,144,144,1);
	    color: #ffffff;
        font-family: 'Lato';
	    font-size: 16px;
    	font-weight: 700;
    	padding: 12.5px 5px;
    	transition: all .5s;
    	&:hover{
    		background-color: rgba(18,50,102,1);
    	}
	}
	&__picture{
	    width: 50%;
	    @media (max-width: 991px) {
    		width: 100%;
    		order: 1;
    	}
	}
	&__img{
		width: 100%;
		height: 100%;
	    object-fit: cover;
    	object-position: right;
	}
}