.request{
	background-color: #e9efff;
	padding-top: 50px;
	padding-bottom: 70px;
	@media (max-width: 767px) {
		padding: 40px 0;
	}
	&__container{
		max-width: 920px;
		width: 100%;
		margin: 0 auto;
		padding: 0 15px;
	}
	&__title{
		text-align: center;
		color: #123266;
		font-family: 'Helvetica';
		font-weight: 400;
	    font-size: 26px;
		padding-bottom: 35px;
	}
}