.word{
	padding-top: 30px;
	text-align: center;
	&__container{
		max-width: 1000px;
		width: 100%;
		padding: 0 15px;
		margin: 0 auto;
	}
	&__title{
		color: #123266;
    	font-family: 'Lato';
		font-size: 35px;
		font-weight: 400;
		padding-bottom: 15px;
		@media (max-width: 767px) {
	    	font-size: 22px;
	    	padding-bottom: 30px;
		}
	}
	&__set{
		display: flex;
		justify-content: center;
		padding-top: 20px;
		padding-bottom: 30px;
		margin-left: -100px;
		flex-wrap: wrap;
		font-family: 'Helvetica';
		font-weight: 400;
		font-size: 14px;
		color: #111;
		@media (max-width: 767px) {
	    	padding-top: 0;
		}
		p{
			padding: 10px 0 10px 100px;
		}
		ul{
			padding-left: 100px;
			list-style-type: none;
			li{
				display: flex;
				align-items: center;
				text-align: left;
				padding: 2px 0;
				font-family: 'Helvetica';
				font-weight: 400;
				font-size: 14px;
				color: #111;
				@media (max-width: 767px) {
			    	font-size: 13px;
				}
			}
		}
	}

}