.major{
	padding: 25px 0;
	text-align: center;
	&__container{
		max-width: 1420px;
		width: 100%;
		margin: 0 auto;
		padding: 0 15px;
	}
	&__background{
		padding: 170px 15px;
		background-image: url(../img/major-background.jpg);
		background-repeat: no-repeat;
		background-position: center;
		background-size: cover;
		@media (max-width: 767px) {
    		padding: 36px 15px;
    	}
	}
	&__title{
		max-width: 864px;
		margin: 0 auto;
		padding: 33px 10px;
		border: 6px solid #ffffff;
		background-color: rgba(18, 50, 102, 0.4);
	    font-family: 'Lato';
	    font-size: 55px;
    	font-weight: 400;
    	color: #ffffff;
    	@media (max-width: 767px) {
    		font-size: 25px;
    	}
	}
	&__knob{
		display: block;
		text-decoration: none;
		max-width: 280px;
		margin: 10px auto;
	    background-color: rgba(18,50,102,1);
	    color: #ffffff;
        font-family: 'Lato';
	    font-size: 16px;
    	font-weight: 700;
    	padding: 11px 5px;
    	transition: all .5s;
    	&:hover{
    		background-color: rgba(146,144,144,1);
    	}
	}
}