*{
	box-sizing: border-box;
	margin: 0;
	padding: 0;
}

.wrapper{
	position: relative;
	overflow: hidden;
	background-color: #ffffff;
}

.indent{
	display: none;
	@media (max-width: 767px) {
		display: block;
	}
}

.index__parallax{
	height: 400px;
	background-attachment: fixed;
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	@media (max-width: 767px) {
		height: 200px;
	}
}