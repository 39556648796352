input, textarea{
	&:focus::-webkit-input-placeholder{ 
		opacity: 0; 
		transition: opacity 0.3s ease;
	}
	&:focus:-moz-placeholder{ 
		opacity: 0; 
		transition: opacity 0.3s ease; 
	} 
	&:focus::-moz-placeholder{ 
		opacity: 0; 
		transition: opacity 0.3s ease; 
	}
	&:focus:-ms-input-placeholder{ 
		opacity: 0; 
		transition: opacity 0.3s ease; 
	}
	&:focus{
		outline: none;
	}
}

.form{
	&__set{
		display: flex;
		flex-wrap: wrap;
		margin-left: -25px;
	}
	&__row{
		padding-left: 25px;
		padding-bottom: 15px;
		width: 33.3%;
		@media (max-width: 991px) {
			width: 50%;
		}
		@media (max-width: 767px) {
			width: 100%;
		}
	}
	&__label{
		display: block;
		color: #111;
		font-family: 'Helvetica';
		font-weight: 400;
		font-size: 14px;
		padding-bottom: 6px;
	}
	&__field{
		width: 100%;
		border: 1px solid rgba(197, 195, 195, 1);
		background-color: #ffffff;
	    padding: 10px;
	    font-family: 'Helvetica';
        font-size: 16px;
	}
	&__textarea{
		width: 100%;
		border: 1px solid rgba(197, 195, 195, 1);
		background-color: #ffffff;
		height: 80px;
		padding: 10px;
		resize: none;
		font-family: 'Helvetica';
	    font-size: 16px;
	}
	&__btn{
		padding-top: 20px;
	}
}

.button{
	background-color: rgb(3,31,81);
	display: block;
	max-width: 280px;
	width: 100%;
	margin-left: auto;
	padding: 10px 5px;
	font-family: 'Helvetica';
    font-size: 16px;
    font-weight: 400;
    border: none;
    color: #ffffff;
    cursor: pointer;
    &:focus{
		outline: none;
	}
}