.footer{
	background-color: #333;
	padding-top: 40px;
	padding-bottom: 20px;
	&__container{
		max-width: 1000px;
		width: 100%;
		margin: 0 auto;
		padding: 0 15px;
	}
	&__set{
		display: flex;
		flex-wrap: wrap;
		margin-left: -30px;
	}
	&__box{
		width: 33.3%;
		padding-left: 30px;
		padding-bottom: 20px;
		@media (max-width: 767px) {
			width: 100%;
		}
	}
	&__row{
		font-family: 'Helvetica';
		color: #ffffff;
		font-weight: 700;
		font-size: 14px;
		padding-bottom: 5px;
		@media (max-width: 767px) {
			text-align: center;
		}
	}
	&__ref{
		font-family: 'Helvetica';
		font-size: 14px;
		color: #ffffff;
		text-decoration: none;
		font-weight: 400;
	}
	&__menu{
		list-style-type: none;
		&-item{
			padding-bottom: 10px;
			&.much{
				.footer__menu-arrow{
					display: block;
				}
			}
			@media (max-width: 767px) {
				text-align: center;
			}
		}
		&-link{
			text-decoration: none;
		    font-family: 'Helvetica';
		    font-size: 14px;
		    font-weight: 400;
		    color: rgba(255, 255, 255, 0.7);
		    transition: all .5s;
		    display: inline-flex;
		    align-items: center;
		    &:hover{
		    	color: rgba(255, 255, 255, 1);
		    }
		    &.effect{
		    	color: rgba(255, 255, 255, 1);
		    	.footer__menu-arrow{
		    		transform: rotate(180deg);
		    	}
		    }
		    &.active{
		    	color: rgba(255, 255, 255, 1);
		    }

		}
		&-arrow{
			min-width: 14px;
			max-width: 14px;
			display: none;
			margin-left: 7px;
			font-size: 14px;
			transition: all .5s;
		}
		&sub{
			list-style-type: none;
			padding-left: 15px;
			height: 0;
			overflow: hidden;
			&.open{
				height: auto;
				padding-top: 10px;
			}
			&-item{
				padding-bottom: 5px;
			}
			&-link{
				text-decoration: none;
			    font-family: 'Helvetica';
			    font-size: 14px;
			    font-weight: 400;
			    color: rgba(255, 255, 255, 1);
			    border-bottom: 1px solid transparent;
			    transition: all .5s;
			    &:hover{
			    	border-bottom: 1px solid rgba(255, 255, 255, 1);
			    }
			    &.active{
			    	border-bottom: 1px solid rgba(255, 255, 255, 1);
			    }
			}
		}
	}
	&__reviews{
		display: block;
		max-width: 180px;
		margin: 0 auto;
	}
	&__networks{
		padding-top: 10px;
		display: flex;
		justify-content: center;
		flex-wrap: wrap;
		&-item{
			padding: 2px;
		}
		&-twitter{
			background: #1da1f2;
		}
		&-link{
			min-width: 44px;
			max-width: 44px;
			height: 44px;
			display: flex;
			align-items: center;
			justify-content: center;
			z-index: 2;
			position: relative;
		    font-size: 24px;
			color: #ffffff;
			position: relative;
			border-radius: 10px;
			&:after {
			    content: '';
			    width: 44px;
			    height: 28px;
			    display: block;
			    position: absolute;
			    top: 0;
			    left: 0;
			    border-radius: 10px;
			    background-color: rgba(255, 255, 255, 0.2);
			}
		}

	}
	&__share{
		padding-top: 30px;
		padding-bottom: 25px;
		max-width: 440px;
		margin: 0 auto;
		&-set{
			display: flex;
			justify-content: center;
			margin-left: -7px;
		}
		&-item{
			padding-left: 7px;
			padding-bottom: 10px;
		}
		&-link{
			display: flex;
			align-items: center;
			align-content: start;
		    background-color: #eee;
		    background-image: linear-gradient(#fff,#dedede);
		    border: #ccc solid 1px;
		    border-radius: 4px;
		    font-family: 'Helvetica';
		    font-weight: 400;
		    font-size: 12px;
		    color: #626262;
		    text-decoration: none;
		    overflow: hidden;
		    padding-right: 6px;
		}
		&-icon{
			width: 25px;
			height: 25px;
			display: flex;
			align-items: center;
			justify-content: center;
			margin-right: 6px;
			svg{
				font-size: 14px;
				color: #ffffff;
			}
		}
		&-facebook{
			.footer__share-icon{
			    background-image: linear-gradient(#6b89c7,#385694);
    			border: 1px solid #33779a;
			}
		}
		&-twitter{
			.footer__share-icon{
			    background-image: linear-gradient(#0ebafb,#0180b0);
    			border: 1px solid #017aa9;
			}
		}
		&-linkedin{
			.footer__share-icon{
			    background-image: linear-gradient(#3096c9,#015d8b);
    			border: 1px solid #01547e;
			}
		}
		&-mail{
			.footer__share-icon{
			   	background-image: linear-gradient(#868787,#535454);
    			border: 1px solid #4b4b4b;
			}
		}
	}
	&__carte{
		padding-bottom: 10px;
		display: flex;
		justify-content: center;
		flex-wrap: wrap;
		&-item{
			display: flex;
			align-items: center;
			padding-bottom: 5px;
			&:after{
				content: '|';
				font-size: 14px;
		    	font-weight: 400;
		    	color: #fefefe;
		    	margin: 0 5px;
			}
			&:last-child:after{
				display: none;
			}
		}
		&-link{
			text-decoration: none;
			font-family: 'Helvetica';
	    	font-size: 14px;
	    	font-weight: 400;
	    	color: #fefefe;
		}
	}
	&__copyright{
		text-align: center;
		font-family: 'Helvetica';
    	font-size: 14px;
    	font-weight: 400;
    	color: #fefefe;
	}
}
