.reviews{
	background-color: #e9efff;
	a{
		color: #031f51;
	}
	&__container{
		max-width: 920px;
		width: 100%;
		margin: 0 auto;
		padding: 0 15px;
	}
}