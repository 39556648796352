.districts{
	padding-top: 20px;
	background-color: rgba(22,37,67,1);
	&__container{
		max-width: 1400px;
		width: 100%;
		margin: 0 auto;
		padding: 0 15px;
	}
	&__set{
		display: flex;
		justify-content: center;
		align-items: center;
		flex-wrap: wrap;
		margin-left: -100px;
		ul{
			padding: 0 0 20px 100px;
			list-style-type: none;
			@media (max-width: 767px) {
				width: 50%;
			}
			@media (max-width: 575px) {
				width: 100%;
				padding: 0 0 20px 120px;
			}
			li{
				display: flex;
				align-items: center;
				text-align: left;
				padding: 2px 0;
				&:before{
					content: '‎●';
					color: #ffffff;
					font-size: 12px;
					margin-right: 8px;
				}
				a{
					text-decoration: none;
					color: #ffffff;
					font-family: 'Helvetica';
					font-weight: 400;
					font-size: 18px;
					@media (max-width: 767px) {
				    	font-size: 13px;
					}
				}
			}
		}
	}
}