.contact{
	padding-top: 73px;
	padding-bottom: 45px;
	background-color: rgba(238, 238, 238, 1);
	@media (max-width: 767px) {
		padding: 15px 0;
	}
	&__wrapper{
		max-width: 995px;
		margin: 0 auto;
		background-color: #ffffff;
		padding: 30px 15px;
		text-align: center;
		@media (max-width: 767px) {
			padding: 20px 15px;
    		margin-bottom: 30px;
    		&:last-child{
    			margin-bottom: 0;
    		}
    	}
	}
	&__title{
		max-width: 904px;
		margin: 0 auto;
	    color: #123266;
	    font-family: 'Lato';
		font-weight: 400;
		font-size: 50px;
		line-height: 70px;
		&:after{
	    	content: '';
	    	display: block;
	    	margin: 26px auto;
	    	width: 40px;
	    	height: 2px;
	    	background-color: rgb(51, 51, 51);
	    	@media (max-width: 767px) {
	    		margin: 20px auto;
	    	}
	    }
	    @media (max-width: 767px) {
    		font-size: 30px;
    		line-height: 40px;
    	}
	}
	&__text{
		max-width: 636px;
		margin: 0 auto;
		font-family: 'Helvetica';
		font-weight: 400;
		font-size: 14px;
	    color: #111;
	    line-height: 23px;
	}
	&__link{
		color: #031f51;
		text-decoration: none;
	}
	&__row{
		color: #111;
	    font-family: 'Helvetica';
		font-weight: 700;
		font-size: 14px;
		padding-bottom: 30px;
		@media (max-width: 767px) {
    		padding-bottom: 20px;
    	}
		&:last-child{
			padding-bottom: 0;
		}
	}
	&__value{
		padding-top: 5px;
		font-weight: 400;
	}
	&__ref{
		font-family: 'Helvetica';
		font-weight: 400;
		font-size: 14px;
	    color: #031f51;
	    text-decoration: none;
	}
}
