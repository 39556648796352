.blog{	
	&__container{
		max-width: 1010px;
    	width: 100%;
		margin: 0 auto;
		padding: 0 15px;
	}
	&__parallax{
		background-attachment: fixed;
	    background-position: 50% -44.2136px;
		background-repeat: no-repeat;
		background-size: cover;
		padding: 108px 0;
		text-align: center;
		position: relative;
		z-index: 1;
		@media (max-width: 767px) {
			padding: 51px 0;
		}
		&:after{
			content: '';
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;
			background-color: rgba(0, 0, 0, 0.2);
			z-index: -1;
		}
	}
	&__title{
		color: #ffffff;
		font-weight: 400;
	    font-family: 'Lato';
		font-size: 45px;
		@media (max-width: 767px) {
			font-size: 30px;
		}
	}
	&__search{
		max-width: 380px;
		width: 100%;
		margin: 40px auto 0 auto;
		position: relative;
		border-radius: 25px;
		border: 1px solid rgba(224, 224, 224, 1);
		overflow: hidden;
		&-field{
			width: 100%;
			padding: 12px 40px 12px 23px;
			border: none;
			font-size: 14px;
    		font-weight: 400;
    		color: rgba(119, 119, 119, 1);
		}
		&-btn{
			background-color: transparent;
			position: absolute;
			right: 10px;
			top: 0;
			width: 30px;
			height: 100%;
			border: none;
			cursor: pointer;
			&:focus{
				outline: none;
			}
		}
	}
	&__set{
		padding-top: 35px;
		padding-bottom: 50px;
		display: flex;
		flex-wrap: wrap;
		margin-left: -30px;
	}
	&__box{
		width: 33.3%;
		padding-left: 30px;
		padding-bottom: 30px;
		@media (max-width: 991px) {
			width: 50%;
		}
		@media (max-width: 767px) {
			width: 100%;
		}
	}
	&__item{
		height: 100%;
		box-shadow: rgba(0,0,0,.25) 0 3px 11px 0;
		&:hover{
			.blog__img{
				transform: scale(1.08);
			}
		}
	}
	&__picture{
		display: block;
		position: relative;
		padding-bottom: 62.2%;
		overflow: hidden;
	}
	&__img{
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		object-fit: cover;
		transition: all .5s;
	}
	&__content{
		padding: 15px;
	}
	&__name{
		display: inline-block;
		font-family: 'Lato';
		color: #123266;
	    font-size: 18px;
    	font-weight: 700;
    	padding-bottom: 15px;
    	text-decoration: none;
    	line-height: 28px;
	}
	&__text{
		max-height: 82.5px;
		overflow: hidden;
		font-family: 'Helvetica';
		font-weight: 400;
		font-size: 15px;
	    color: #747474;
	    line-height: 20px;
	}
}