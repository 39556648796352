.header{
	background-color: #182849;
	position: relative;
	z-index: 10;
	@media (max-width: 767px) {
		position: fixed;
		left: 0;
		top: 0;
		width: 100%;
	    background-color: #123266;
	    box-shadow: rgba(0, 0, 0, .25) 0 3px 11px 0;
	}
	&__upper{
		background-image: url(../img/header-detail.png);
		background-repeat: repeat;
    	background-size: auto;
		padding: 29px 0 24.5px;
		@media (max-width: 767px) {
			padding: 12px 0;
			background-image: none;
		}
		&-container{
			max-width: 990px;
			width: 100%;
			margin: 0 auto;
			padding: 0 15px;
			display: flex;
			align-items: flex-start;
			justify-content: space-between;
			@media (max-width: 767px) {
				align-items: center;
			}
		}
	}
	&__ref{
		display: inline-block;
		max-width: 347px;
		@media (max-width: 767px) {
			max-width: 138px;
			margin: 0 15px;
		}
	}
	&__logotype{
		width: 100%;
	}
	&__contacts{
		padding-top: 30px;
		@media (max-width: 767px) {
			display: none;
		}
	}
	&__phone{
		display: none;
		@media (max-width: 767px) {
			display: block;
			width: 27px;
			height: 27px;
		}
		svg{
			color: #e9efff;
    		fill: #e9efff;
		}
	}
	&__row{
		text-align: right;
		font-family: 'Belgrano';
		font-weight: 700;
	    font-size: 16px;
		color: #ffffff;
		padding-bottom: 10px;
		&:last-child{
			padding-bottom: 0;
		}
	}
	&__tel{
		font-family: 'Belgrano';
		font-weight: 700;
	    font-size: 16px;
		color: #ffffff;
	}
	&__mail{
		font-family: 'Belgrano';
		font-weight: 400;
	    font-size: 14px;
		color: #ffffff;
	}
	&__lower{
		border-top: 1px solid #ffffff;
		padding: 15px 0;
		&-container{
			max-width: 1480px;
			width: 100%;
			margin: 0 auto;
			padding: 0 15px;
		}
		@media (max-width: 767px) {
			padding: 0;
			border-top: none;
		}
	}
	&__nav{
		@media (max-width: 767px) {
			position: fixed;
			top: -100%;
			left: 0;
			height: 100%;
			width: 100%;
			background-color: #182849;
			z-index: 11;
			transition: all .5s;
			overflow: auto;
			padding-top: 100px;
			padding-bottom: 40px;
		}
		&.open{
			top: 0;
		}
	}
	&__toggle{
		width: 27px;
		height: 14px;
		display: none;
		@media (max-width: 767px) {
			display: block;
		}
	}
	&__knob{
		position: fixed;
		z-index: 12;
		width: 22px;
		height: 14px;
		display: flex;
    	flex-direction: column;
    	justify-content: space-between;
		span{
		    display: block;
		    height: 0;
		    width: 100%;
		    border: 1px solid rgb(233, 239, 255);
		    transition: transform .3s ease-out,opacity .4s ease-out;
		    transition: transform .3s ease-out,opacity .4s ease-out,-webkit-transform .3s ease-out;
		}
		&.active{
			span{
				&:first-child{
				    transform: translateY(6px) rotate(45deg);
				}
				&:nth-child(2){
				    opacity: 0;
				}
				&:last-child{
				    transform: translateY(-6px) rotate(-45deg);
				}
			}
		}
	}
}

.menu{
	list-style-type: none;
	display: flex;
	justify-content: center;
	flex-wrap: wrap;
	margin-bottom: 0;
	&__item{
		margin: 2px 15px;
		position: relative;
		@media (max-width: 767px) {
			display: block;
			width: 100%;
			padding: 4px 0;
			text-align: center;
		}
		&.much{
			.menu__arrow{
				display: block;
			}
		}
	}
	&__link{
		font-family: 'Belgrano';
		font-weight: 400;
		font-size: 14px;
		color: #ffffff;
		text-decoration: none;
		position: relative;
		transition: all .5s;
		padding: 5px 0;
		display: inline-flex;
		align-items: center;
		&:after{
			content: '';
			position: absolute;
			height: 2px;
			width: 0;
			left: 0;
			bottom: 0;
			background-color: #ffffff;
			transition: all .5s;
		}
		&:hover{
			&:after{
				width: 100%;
			}
		}
		@media (max-width: 767px) {
			font-family: 'Lato';
		}
		&.active{
			&:after{
				width: 100%;
			}
		}
		&.effect{
			.menu__arrow{
	    		transform: rotate(180deg);
	    	}
		}
	}
	&__arrow{
		min-width: 14px;
		max-width: 14px;
		display: none;
		margin-left: 7px;
		font-size: 14px;
		transition: all .5s;
	}
	&sub{
		position: absolute;
		top: 100%;
		height: 0;
		overflow: hidden;
		right: 0;
		list-style-type: none;
		background-color: #182849;
	    width: max-content;
	    &.open{
			height: auto;
			padding-top: 10px;
		}
	    @media (max-width: 767px) {
			position: relative;
			top: 0;
			width: auto;
			padding-top: 10px;
		}
	}
	&__itemsub{
		border-bottom: 1px solid #7198ff;
		&:last-child{
			border-bottom: none;
		}
		@media (max-width: 767px) {
			border-bottom: none;
		}
	}
	&__linksub{
		font-family: 'Helvetica';
		font-weight: 400;
		display: block;
		padding: 15px 30px 15px 15px;
		text-decoration: none;
		color: #ffffff;
		transition: all .5s;
		&.active{
			background-color: #000000;
		}
		&:hover{
			background-color: #000000;
		}
		@media (max-width: 767px) {
			display: inline-block;
			color: #999;
			padding: 5px 0;
			&:hover{
				background-color: transparent;
				color: #ffffff;
			}
			&.active{
				background-color: transparent;
				color: #ffffff;
			}
		}
	}
}